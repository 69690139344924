<template>
  <div class="schedule-group-events-container" :style=" show ? 'margin-bottom: 10px;' : '' " >
    <v-timeline-item
      class="simple-event white--text noselect"
      :class="{'schedule-group-events-toggle': show}"
      color="black"
    >
      <template v-slot:icon v-if="groupedEvents[0].avatar">
        <v-avatar size="32">
          <img :src="groupedEvents[0].avatar">
        </v-avatar>
      </template>

      <v-card-title primary-title style="z-index=5" @click="show = !show">
        <v-layout row wrap>
          <v-flex xs12 sm2 md2 v-if="!show">
            <span v-if="!isLive && isPast" class="grey--text text--darken-2 font-weight-medium">{{ groupedEvents[0].start_at.format('LT') }}</span>
            <span v-if="!isLive && nextLivetime && !isPast" class="grey--text text--lighten-1 font-weight-medium">{{ nextLivetime.format('LT') }}</span>
            <div v-if="isLive" class="live-sub-title font-weight-medium"><span class="red-dot blink"></span> LIVE NOW</div>
          </v-flex>
          <v-flex xs12 sm2 md2 v-else></v-flex>

          <!-- single event -->
          <v-flex xs12 sm10 md10>
            <v-layout row wrap>
              <v-flex xs10 sm6 md4>
                <span class="grey--text text--lighten-4" :class="{'font-weight-bold': show}">
                  {{ groupedEvents[0].groupName }}
                </span>
              </v-flex>
              <v-flex xs10 sm6 md8>
                <span class="grey--text text--lighten-1">Daily Schedule</span>
                <div class="schedule-group-expand">
                  <div class="material-icons" v-if="show">expand_less</div>
                  <div class="material-icons" v-if="!show">expand_more</div>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-title>
    </v-timeline-item>

    <div class="schedule-group-events" v-if="show">
      <div v-for="(event, index) in groupedEvents">
        <single-event :event="event" league :key="updateKey" />
      </div>
    </div>
  </div>
</template>

<script>
import { get, commit, sync, call } from 'vuex-pathify'
import singleEvent from './single-event'

export default {
  name: 'group-event',
  components: {
    singleEvent,
  },
  data: () => ({
    show: false,
    isPast: false,
    isLive: false
  }),
  props: {
    groupedEvents: {
      required: true,
      type: Array,
      default: null
    },
    updateKey: {
      required: true,
      type: Number,
      default: null
    },
  },
  mounted() {
    this.updateStatus()
    this.$nextTick(() => {
      this.updateStatus()
    })

    window.addEventListener("focus", this.updateStatus, false) // Update when mobile is refocused
  },
  watch: {
     updateKey: function(newVal, oldVal) {
       this.updateStatus()
     },
     upcomingFilter: function(newVal, oldVal) {
       this.updateStatus()
     }
   },
  computed: {
    upcomingFilter: get('schedule/filters@upcoming'),
    nextLivetime() {
      let now = this.$moment();

      let result = this.groupedEvents.find(function(groupEvent) {
        let startTime = groupEvent.start_at
        if (startTime.isAfter(now)) {
          return groupEvent
        }
      }, this);

      if (result) {
        return result.start_at
      } else {
        false
      }
    }
  },
  methods: {
    updateStatus() {
      this.updateIsPast()
      this.updateIsLive()
    },
    updateIsLive() {
      let now = this.$moment();

      let result = this.groupedEvents.some(function(groupEvent) {
        return now.isBetween(groupEvent.start_at, groupEvent.end_at);
      }, this);

      this.isLive = result
    },
    updateIsPast() {
      let now  = this.$moment();

      var result = this.groupedEvents.find(function(groupEvent) {
        let endTime = groupEvent.end_at
        if (endTime.isAfter(now)) {
          return groupEvent
        }
      }, this);

      if (result) {
        this.isPast = false
      } else {
        this.isPast = true
        if (this.upcomingFilter) {
          this.$store.dispatch("schedule/deleteEvent", this.groupedEvents[0].id)
        }
      }
    },
  }
}
</script>

<style lang="scss">
.schedule-group-events {
  .v-timeline-item__dot {
    opacity: 0;
  }

  .v-timeline-item {
    padding-bottom: 2px !important;
  }

  .v-timeline-item__body {
    border-radius: 0;
    border-left: 4px solid gray;
    background-color: #1b1b1c;
  }
}
.schedule-group-events-toggle {
  padding-bottom: 2px !important;

  .v-timeline-item__body {
    border-left: 4px solid gray;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #2f2f33;
  }
}
.schedule-group-expand {
  text-align: right;
  display: inline;

  .material-icons {
    text-align: right;
    float: right;
    display: block;
    height: 20px;
  }

  // Mobile
  @media (max-width: 600px) {
    position: absolute;
    right: 10px;
    top: 30px;
  }
  // Tablet and Desktop
  @media (min-width: 600px) {
    display: inline;
  }
}
</style>
