import NProgress   from 'vue-nprogress'

const nprogress = new NProgress({
  showSpinner: false,
  speed: 400,
  trickleSpeed: 800
})

// Create plugin
let NProgressPlugin = {}

NProgressPlugin.install = function (Vue, options) {
  Vue.nprogress = nprogress // Access globally (from class)
  Vue.prototype.$nprogress = nprogress // acccess from vue instance
}

export default NProgressPlugin
