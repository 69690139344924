<template>
  <!-- Featured -->
  <v-timeline-item
    color="#AC0000"
    v-if="event.featured"
  >
    <template v-slot:icon v-if="event.avatar">
      <v-avatar size="32">
        <img :src="event.avatar">
      </v-avatar>
    </template>

    <v-card class="featured-card noselect" @click="goToStream()">
       <v-layout wrap align-center justify-center row fill-height>
         <v-flex
           xs12
           sm5
           md5
           grow
         >
           <v-card-title primary-title>
             <div style="line-height: 2;">
               <span v-if="!isLive" class="grey--text font-weight-medium" :class="{'text--lighten-1': !isPast, 'text--darken-2': isPast}">{{ event.start_at.format('LT') }}</span>
               <div v-if="isLive" class="live-sub-title font-weight-medium"><span class="red-dot blink"></span> LIVE NOW</div>
               <div class="featured-title">{{ event.name }}</div>
               <div><span class="grey--text text--lighten-1 subheading">{{ event.competition }}</span></div>
               <div><event-chips :event="event" v-if="hasUser" /></div>
             </div>
           </v-card-title>
         </v-flex>

         <v-flex
           xs12
           sm7
           md7
           fill-height
           style="line-height: 0; text-align: right; padding: 15px 20px 15px 20px;"
         >
           <img :src="event.banner" alt="" fill-height class="featured-banner elevation-5">
         </v-flex>
       </v-layout>
     </v-card>
   </v-timeline-item>


   <!-- Simple -->
   <v-timeline-item
     class="simple-event white--text noselect"
     color="black"
     v-else
   >
     <template v-slot:icon v-if="event.avatar">
       <v-avatar size="32">
         <img :src="event.avatar">
       </v-avatar>
     </template>

     <v-card-title primary-title style="z-index=5" @click="goToStream(event)">
       <v-layout row wrap>
         <v-flex xs12 sm2 md2>
           <span v-if="!isLive" class="grey--text font-weight-medium" :class="{'text--lighten-1': !isPast, 'text--darken-2': isPast}">{{ event.start_at | moment("LT") }}</span>
           <div v-if="isLive" class="live-sub-title font-weight-medium"><span class="red-dot blink"></span> LIVE NOW</div>
         </v-flex>

         <!-- single event -->
         <v-flex xs12 sm10 md10>
           <v-layout row wrap>
             <v-flex xs12 sm6 md4>
               <span class="grey--text text--lighten-4">{{ eventTitle }}</span>
             </v-flex>

             <v-flex xs12 sm6 md3 v-if="!league">
               <span class="grey--text text--lighten-1">{{ event.competition }}</span>
             </v-flex>

             <!-- chips for league events -->
             <v-flex xs12 sm12 md8 v-if="league">
               <event-chips :event="event" league v-if="hasUser" />
             </v-flex>
             <!-- chips for normal event -->
             <v-flex xs12 sm12 md5 v-else>
               <event-chips :event="event" v-if="hasUser"/>
             </v-flex>
           </v-layout>
         </v-flex>
       </v-layout>
     </v-card-title>
   </v-timeline-item>
</template>

<script>
import { get } from 'vuex-pathify'
import eventChips from './event-chips'

export default {
  name: 'single-event',
  components: {
    eventChips,
  },
  data: () => ({
  }),
  props: {
    event: {
      required: true,
      type: Object,
      default: null
    },
    league: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    upcomingFilter: get('schedule/filters@upcoming'),
    hasUser: get('schedule/user'),
    isLive() {
      let now = this.$moment();
      return now.isBetween(this.event.start_at, this.event.end_at);
    },
    isPast() {
      let now = this.$moment();
      let is_past = now.isAfter(this.event.end_at)
      if (is_past && this.upcomingFilter && !this.league) {
        this.$store.dispatch("schedule/deleteEvent", this.event.id)
      }
      return is_past
    },
    isEnded() {
      if (this.upcomingFilter) { // If upcoming is toggled
        return this.isPast
      } else { // If past is toggled
        return false
      }
    },
    eventTitle() {
      if (this.event.alt_name) {
        return this.event.alt_name
      } else {
        return this.event.name
      }
    },
    hasLeagueEvent() {
      return this.league && this.event.streams.length == 0
    },
    hasLeagueChannel() {
      var groupType = null
      if (this.event.streams.length >= 1) {
        var groupType = this.event.streams[0].type
      }
      return ![null, 'main', 'channel'].includes(groupType)
    },
  },
  methods: {
    selectAndGo(streamId) {
      if (this.hasLeagueEvent || this.hasLeagueChannel) { this.$globalEvent.$emit("league-toggle", { groupName: this.event.groupName } ) } // open league dropdown
      this.$store.dispatch("streams/navigateToStream", { id: streamId })
      setTimeout(() => { this.$scrollTo("#stream-" + streamId) }, 300)
    },
    goToStream() {
      if (!this.hasUser) { window.location.href = '//rapidvps.is/amember/signup'; return } // For landing page
      if (this.event.alt_name && this.event.alt_name.includes("/")) { console.log("external"); return } // For external links

      let now          = this.$moment()
      let startAt      = this.event.start_at.clone()
      let endAt        = this.event.end_at.clone()
      var isSetToStart = now.isAfter(startAt.subtract(15, 'minutes'))  &&  !now.isAfter(endAt.add(30, 'minutes')) // current time is 15 minutes before event

      let found = this.$store.get('streams/findEventByName', this.event.name)

      if ( found.length >= 1 && isSetToStart ) { // has a stream in Event section
        this.selectAndGo(found[0].id)
      } else if ( this.event.streams.length >= 1  && isSetToStart ) { // has stream in channels present
          this.selectAndGo(this.event.streams[0].id)
      } else { // show dialog
        this.$globalEvent.$emit("schedule-modal-open", this.event)
      }
    },
  }
}
</script>

<style lang="scss">
$bubble-color: #202021;

// Single ---------------------------------------------------------------------
.simple-event {
  .v-timeline-item__body {
    padding: 0px 15px;
    border-radius: 10px;

    background-color: $bubble-color;

    &:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.08);
      box-shadow:
        0 0.4px 3.8px -5px rgba(0, 0, 0, 0.067),
        0 1px 9.1px -5px rgba(0, 0, 0, 0.097),
        0 1.9px 17.2px -5px rgba(0, 0, 0, 0.12),
        0 3.4px 30.6px -5px rgba(0, 0, 0, 0.143),
        0 6.3px 57.2px -5px rgba(0, 0, 0, 0.173),
        0 15px 137px -5px rgba(0, 0, 0, 0.24)
      ;

      li {
        // background-color: rgba(0, 0, 0, 0.01);
      }
    }
  }

  .v-card__title {
    padding-left: 0 !important;
    padding: 13px 0px !important;
  }
  // Desktop
  @media (min-width: 600px) {
    .v-card__title {
      padding: 30px;
    }
  }
}

// Featured -------------------------------------------------------------------
.featured-card {
  box-shadow: none;
  border-color: none;
  border-bottom: 0px !important;
  border-radius: 10px;
  background-color: $bubble-color !important;

  .featured-banner {
    height: 100%;
    border-radius: 15px;
  }

  .v-card__title--primary {
    padding-top: 10px;
    padding-bottom: 0px;
    .featured-title {
      font-size: 17px;
      font-weight: 400;
      line-height: 1;
      // letter-spacing: 0.02em;
      // font-family: 'Roboto', sans-serif !important;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.08) !important;
    box-shadow:
      0 0.4px 3.8px -5px rgba(0, 0, 0, 0.067),
      0 1px 9.1px -5px rgba(0, 0, 0, 0.097),
      0 1.9px 17.2px -5px rgba(0, 0, 0, 0.12),
      0 3.4px 30.6px -5px rgba(0, 0, 0, 0.143),
      0 6.3px 57.2px -5px rgba(0, 0, 0, 0.173),
      0 15px 137px -5px rgba(0, 0, 0, 0.24)
    ;
  }

  // Mobile
  @media (max-width: 600px) {
    .featured-banner {
      width: 100%;
    }
    .schedule-chips {
      text-align: left;
      margin-top: -5px;
      .v-chip {
      }
    }
  }
  // Tablet
  @media (min-width: 600px) and (max-width: 960px) {
    .featured-banner {
      height: auto;
      width: 100%;
    }
    .featured-title {
      margin-bottom: 2px;
    }
    .subheading {
      line-height: 1;
    }
    .schedule-chips {
      text-align: left;
      margin-top: -2px;
    }
  }
  // Desktop
  @media (min-width: 960px) {
    .featured-banner {
      max-height: 160px;
    }
    .schedule-chips {
      text-align: left;
      margin-top: 10px;

      .v-chip {
      }
    }
  }
}
</style>
