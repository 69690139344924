import axios       from 'axios'
import getFormData from './get-form-data'

// CSRF-Token
document.addEventListener('DOMContentLoaded', () => {
  const csrfElement = window.document.querySelector("meta[name=csrf-token]")
  if (csrfElement) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfElement.content
  }
})

// Axios Wrapper
class Http {
  getClean(path, parameters) {
    return axios.get(`${path}`,
      { params: parameters }
    )
  }

  get(path, parameters) {
    return axios.get(`${path}.json`,
      { params: parameters }
    )
  }

  post(path, data) {
    return axios.post(
      `${path}.json`,
      data
    )
  }

  put(path, data) {
    return axios.put(
      `${path}.json`,
      data
    )
  }

  patch(path, data) {
    return axios.patch(
      `${path}.json`,
      data
    )
  }

  formPatch(path, data, objectName) {
    return axios.patch(`${path}.json`,
      getFormData(data, objectName),
      { headers: { 'content-type': 'multipart/form-data' } }
    )
  }

  delete(path, submitData) {
    return axios.delete(
      `${path}.json`,
      { data: submitData }
    )
  }

  redirectTo(path) {
    return window.location = path
  }

  refresh() {
    return window.location.reload()
  }
}

const http = new Http

export default http
