// Converts an Object into a form data: useful when submitting images
// requires the object and the parent name
function getFormData(object, objectName) {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    if (object[key] !== null && typeof object[key] !== 'undefined') {
      formData.append(`${objectName}[${key}]`, object[key])
    }
  });
  return formData;
}

export default getFormData;
