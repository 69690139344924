<template>
  <v-toolbar
    app
    flat
    :height="navbarHeight"
    color="#000"
  >
    <v-btn
      v-if="withSchedule"
      flat href="#schedule"
      class="hidden-sm-and-down mr-5"
    >
      <span class="mr-2">Schedule</span>
    </v-btn>

    <v-spacer class="hidden-sm-and-down"></v-spacer>

    <a href="/" :class="{ 'without-schedule': !withSchedule }">
      <img
        id="logo"
        :src="logoImgPath"
      />
    </a>

    <v-spacer></v-spacer>

    <v-btn
      flat
      href="/signin"
    >
      <span class="mr-2">Sign In</span>
    </v-btn>
    <v-btn
      href="//rapidvps.is/amember/signup"
      :color="buyButton"
      :outline="navBarActionButton"
      :light="navBarActionButton"
      class="hidden-sm-and-down"
      rel="nofollow"
     >
      <span class="mr-2">Sign Up</span>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { get } from 'vuex-pathify'
import LogoImg from "@assets/images/brand.svg"
  export default {
    data: () => ({
      logoImgPath: LogoImg,
      navBarActionButton: false
    }),
    mounted() {
      window.addEventListener("scroll", this.onScroll)
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.onScroll)
    },
    computed: {
      navbarHeight () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '75px'
          case 'sm': return '80px'
          case 'md': return '100px'
          case 'lg': return '100px'
          case 'xl': return '100px'
        }
      },
      buyButton() {
        if (this.navBarActionButton) {
          return "action"
        } else {
          return "black"
        }
      },
      withSchedule: get('schedule/status')
    },
    methods: {
      onScroll(e) {
        if (window.scrollY <= 350) {
          this.navBarActionButton = false
        } else {
          this.navBarActionButton = true
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
#logo {
  margin-top: 2px;
  height: 50px;

  @media (min-width: 960px) { // Desktop
    height: 80px;
    margin-top: 4px;
    margin-left: 50px;
  }
}

.without-schedule {
  #logo {
    @media (min-width: 960px) {
      margin-left: 200px;
    }
  }
}
</style>
