/* eslint no-console:0 */

import Vue from 'vue'
import '../plugins'
import LandingApp from '../landing-app.vue'

// Store
import Vuex  from 'vuex'
import pathify from 'vuex-pathify'
Vue.use(Vuex)
import schedule from '@store/modules/schedule'
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  plugins: [ pathify.plugin ],
  modules: { schedule },
  strict: debug
})

// Google Analytics
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: 'UA-141756163-1',
//   debug: { sendHitTask: process.env.NODE_ENV === 'production' }
// })

Vue.config.productionTip = false

// Load Vue
document.addEventListener('DOMContentLoaded', () => {
  const landingAppTag  = "#landing-app"

  // Signed in
  if (document.querySelector(landingAppTag)) {
    new Vue({
      store,
      render: h => h(LandingApp),
    }).$mount(landingAppTag)
  }
})
