import http from './http'

// Create plugin
let HttpPlugin = {}

HttpPlugin.install = function (Vue, options) {
  Vue.http = http // Access globally (from class)
  Vue.prototype.$http = http // acccess from vue instance
}

export default HttpPlugin
