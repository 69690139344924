<template>
  <div class="header-container">
    <v-container mb-5>
      <v-layout text-xs-center wrap align-center>
        <v-flex sm12 md12 lg5 xl3 offset-xl2>
          <h1 class="display-2 font-weight-bold mb-3">
            Live stream of PPV and sporting events in HD
          </h1>
          <div>
            <!-- <v-btn href="#schedule" v-if="status">Schedule</v-btn> -->
            <v-btn color="action" href="//rapidvps.is/amember/signup" rel="nofollow" light large>Try It Now <v-icon style="margin-left:10px;">double_arrow</v-icon></v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  data: () => ({
  }),
  computed: {
    status: get('schedule/status')
  }
}
</script>

<style lang="scss">
  .header-container {
    background-color: #181818;
    background: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("/landing/header-background-mobile.jpg") center center no-repeat;
    padding-top: 60px;
    padding-bottom: 40px;
  }

  @media (min-width: 1264px) {
    .header-container {
      padding-top: 120px;
      padding-bottom: 50px;
      background: #181818 url("/landing/header-background.jpg") center right no-repeat;
    }
  }
</style>
