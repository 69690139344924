<template>
  <div class="featured-container featured-container-alt">
    <v-container grid-list-md text-xs-center>
      <h2 class="display-2 font-weight-bold mb-3">What to expect?</h2>
      <p class="subheading grey--text mb-5">Here are just some of the competitions we stream.</p>

      <v-layout row wrap>
        <v-flex v-for="comp in competitions" :key="comp.name" fill-height xs6 sm4 md3 >
          <v-card dark color="black" fill-height class="pt-3 pb-3">
            <div style="height:100px;" justify-center align-center>
              <img :src="comp.img" :alt="comp.name" width="100px" style="padding: 10px;" fill-height>
            </div>
            <v-card-text class="px-0">{{comp.name}}</v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    "competitions": [
      {
        "name": "Pay-Per-View Sports",
        "img": require('@assets/images/competitions/ppv.png')
      },
      {
        "name": "NFL",
        "img": require('@assets/images/competitions/NFL.png')
      },
      {
        "name": "NBA",
        "img": require('@assets/images/competitions/NBA.png')
      },
      {
        "name": "MLB",
        "img": require('@assets/images/competitions/MLB.png')
      },
      {
        "name": "NHL",
        "img": require('@assets/images/competitions/NHL.png')
      },
      // {
      //   "name": "La Liga",
      //   "img": require('@assets/images/competitions/LaLiga.png')
      // },
      // {
      //   "name": "Bellator",
      //   "img": require('@assets/images/competitions/Bellator.png')
      // },
      {
        "name": "PFL",
        "img": require('@assets/images/competitions/PFL.png')
      },
      // {
      //   "name": "EFL Championship",
      //   "img": require('@assets/images/competitions/EFLChampionship.png')
      // },
      {
        "name": "Champions League",
        "img": require('@assets/images/competitions/ChampionsLeague.png')
      },
      // {
      //   "name": "Europa League",
      //   "img": require('@assets/images/competitions/EuropaLeague.png')
      // },
      {
        "name": "Formula One",
        "img": require('@assets/images/competitions/F1.png')
      },
      // {
      //   "name": "EFL Cup",
      //   "img": require('@assets/images/competitions/league_cup.png')
      // }
    ]
  })
}
</script>

<style>
</style>
