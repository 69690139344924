<template>
  <div
    class="schedule-chips"
    v-if="eventChips && eventChips.length >= 1 || channelChips && channelChips.length >= 1"
  >
    <v-chip
      color="secondary"
      text-color="white"
      v-for="(stream, index) in eventChips"
      v-if="exist(stream)"
      :key="stream.id"
      @click.stop="goToStream(stream)"
    >
      <span >{{ index + 1 }}</span>
    </v-chip>

    <v-chip
      color="secondary"
      text-color="white"
      v-for="(stream, index) in channelChips"
      v-if="exist(stream) && !stream.name.includes(' at ') && !stream.name.includes(' vs ')"
      :key="stream.id"
      @click.stop="goToStream(stream)"
    >
      <span>{{ stream.name }}</span>
    </v-chip>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify'

export default {
  name: 'event-chips',
  data: () => ({
  }),
  props: {
    event: {
      required: true,
      type: Object,
      default: null
    },
    league: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    eventChips() {
      return this.$store.get('streams/findEventByName', this.event.name)
    },
    channelChips() {
      return this.event.streams
    },
  },
  methods: {
    exist(currentStream) {
      return this.$store.get('streams/findEventById', currentStream.id)
    },
    isLeague(groupName) {
      return this.league && ![null, 'main', 'channel'].includes(groupName)
    },
    goToStream(currentStream) {
      if (this.isLeague(currentStream.type)) { this.$globalEvent.$emit("league-toggle", { groupName: this.event.groupName} ) } // open league dropdown
      this.$store.dispatch("streams/navigateToStream", { id: currentStream.id });
      setTimeout(() => { this.$scrollTo("#stream-" + currentStream.id) }, 300);
    },
  }
}
</script>

<style lang="scss">
.schedule-chips {
  margin-right: -5px;
  margin-left: -5px;


  .v-chip {
    .v-chip__content {
      &:hover {
        cursor: pointer !important;
        background-color: rgba(255, 255, 255, 0.09);
      }
    }
  }
}

// Mobile
@media (max-width: 960px) {
  .schedule-chips {
    padding-top: 5px;
    margin-bottom: -5px;

    .v-chip {

    }
  }
}
// Tablet and Desktop
@media (min-width: 960px) {
  .schedule-chips {
    text-align: right;
    height: 20px;

    .v-chip {
      margin: 2px;
      margin-top: -4px;
      font-size: 11px;

      .v-chip__content {
        height: 28px;
        padding: 0 9px;
      }
    }
  }
}
</style>
