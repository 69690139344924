<template>
  <v-layout row align-center>
    <div id="ad-container">
      <img src="/ad.gif" id="ad" width="1" height="1">
    </div>
    <v-alert
      :value="adBlockActive"
      type="warning"
      class="mb-5"
      outline
    >
      Please make sure to turn off your adblockers as it may block streams.
    </v-alert>
  </v-layout>
</template>

<script>

export default {
  name: 'ad-block-msg',
  data: () => ({
    adBlockActive: true,
  }),
  props: {
  },
  mounted() {
    this.detectAdBlock()
  },
  methods: {
    detectAdBlock() {
      let el = document.getElementById("ad-container")

      if(el.offsetHeight === 0) {
        this.adBlockActive = true
      } else {
        this.adBlockActive = false
      }
    }
  }
}
</script>
