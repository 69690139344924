<template>
  <v-container grid-list-md text-xs-center class="featured-container">
    <v-layout align-center justify-center row wrap fill-height>
      <v-flex v-for="feature in features" :key="feature.title" fill-height xs12 md4 lg3 xl2 >
        <v-icon style="font-size: 80px" color="#AC0000" class="mb-2">{{feature.icon}}</v-icon>
        <div class="display-1 mb-1 second">{{feature.title}}</div>
        <p class="subheading grey--text">{{feature.text}}</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      "features": [
        {
          "title": "Reliable Streams",
          "text": "High availability CDN streams with clear HD quality video",
          "icon": "verified_user"
        },
        {
          "title": "IPTV",
          "text": "Watch from your TV with support for IPTV apps across different platforms",
          "icon": "live_tv"
        },
        {
          "title": "No Ads",
          "text": "Stop dealing with annoying ads and popups on your video streams",
          "icon": "speaker_notes_off"
        }
      ]
    })
  }
</script>

<style>
</style>
