<template>
  <v-app dark>
    <landing-navbar />
    <v-content>
      <landing-header />
      <landing-features />
      <landing-competitions />
      <schedule is-landing-page />
      <div class="buy-footer">
        <v-btn  color="action" href="//rapidvps.is/amember/signup" rel="nofollow" light large>Sign UP NOW <v-icon style="margin-left:10px;">double_arrow</v-icon></v-btn>
      </div>
    </v-content>
    <landing-footer />
  </v-app>
</template>

<script>
import landingNavbar from '@views/landing/landing-navbar'
import landingHeader from '@views/landing/landing-header'
import landingFeatures from '@views/landing/landing-features'
import landingCompetitions from '@views/landing/landing-competitions'
import landingFooter from '@views/landing/landing-footer'
import schedule from '@views/schedule'

export default {
  name: 'landing-app',
  components: {
    landingNavbar,
    landingHeader,
    landingFeatures,
    landingCompetitions,
    schedule,
    landingFooter
  },
  data () {
    return {
    }
  },
  mounted() {
    // this.$ga.page('/');
  }
}
</script>

<style lang="scss" scoped>
  .theme--dark.application {
    background: #0f1011;
  }
  .featured-container {
    padding-top: 120px;
    padding-bottom: 130px;
  }
  .featured-container-alt {
    background-color: #181818;
  }
  .buy-footer {
    text-align: center;
    padding: 60px 0;

    .v-btn {
      font-size: 16px;
      height: 48px;
      padding: 0 48px;
    }
  }
  // This applies only if schedule is present
  #schedule + .buy-footer {
    margin-top: -30px;
    margin-bottom: 70px;
    padding: 0;
  }
</style>
