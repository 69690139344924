import Vue from 'vue'
import { make } from 'vuex-pathify'

// initial state
const state = {
  user: false,
  status: false,
  busy: false,
  timelines: [],
  eventCount: null,
  categories: [],
  filters: {
    upcoming: true,
    search: "",
    category: undefined,
    offset: 0
  }
}

// helpers
function processNewTimelines(value) {
  return value.map(function(event) {
    if (Array.isArray(event)) {
      event.map(function(group_event){
        group_event.start_at = Vue.moment.unix(group_event.start_at)
        group_event.end_at = Vue.moment.unix(group_event.end_at)
        return group_event
      })
    } else { // get date for single events
      event.start_at = Vue.moment.unix(event.start_at)
      event.end_at = Vue.moment.unix(event.end_at)
    }
    return event
  })
}

// getters
const getters = {
  ...make.getters(state),
  loadedEventCount(state) {
    return state.timelines.length
  },
  eventByName(state, event_name) {
    return state.timelines.find(event => event.name === event_name);
  },
  findIndexById: (state) => (id) => {
    return state.timelines.findIndex((event) => {
      if (Array.isArray(event)) {
        return event[0].id == id
      } else {
        return event.id == id
      }
    })
  },
  timelines(state) {
    // this gives an object with dates as keys
    let groups = state.timelines.reduce((groups, event, currentIndex) => {
      // Get date for group/league events
      if (Array.isArray(event)) {
        event[0].index = currentIndex
        var date = event[0].start_at.format("YYYY-MM-DD");
      } else { // get date for single events
        event.index = currentIndex
        var date = event.start_at.format("YYYY-MM-DD");
      }

      if (!groups[date]) { groups[date] = []; }
      groups[date].push(event);
      return groups;
    }, {});

    // convert to array with keys
    let groupArrays = Object.keys(groups).map((date) => {
      return { date, events: groups[date] };
    });

    return groupArrays;
  }
}

// mutations
const mutations = {
  ...make.mutations(state),
  SET_TIMELINES(state, value) {
    state.timelines = processNewTimelines(value)
  },
  ADD_TIMELINES(state, value) {
    state.timelines.push(...processNewTimelines(value))
  },
  SET_OFFSET(state, value) {
    state.filters.offset = value
  },
  DELETE_EVENT(state, eventIndex) {
    if (Number.isFinite(eventIndex) && eventIndex >= 0) {
      // console.log("deleting index: " + eventIndex);
      state.timelines.splice(eventIndex, 1)
    }
  }
}

// actions
const actions = {
  deleteEvent({ getters, commit, dispatch }, eventId) {
    return new Promise((resolve, reject) => {
      let eventIndex = getters.findIndexById(eventId)
      commit('DELETE_EVENT', eventIndex)
    })
  },
  async loadTimelines({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      Vue.nprogress.start()
      commit('SET_OFFSET', 0); // Reset offset for new search
      commit('SET_BUSY', true);

      Vue.http.get('/schedule_events',
        getters.filters
      ).then(response => {
        commit("SET_STATUS", response.data.status); // landing schedule option
        commit("SET_USER", response.data.user); // signed in

        // load only if signed in or landing schedule option is on
        if (response.data.status || response.data.user) {
          commit("SET_TIMELINES", response.data.timelines);
          commit("SET_EVENT_COUNT", response.data.eventCount);
          commit("SET_CATEGORIES", response.data.categories);
        }

        commit('SET_BUSY', false);
        resolve();
        Vue.nprogress.done()
      }).catch(error => {
        console.log(error);
        commit('SET_BUSY', false);
        Vue.nprogress.done()
      })
    })
  },
  async loadMoreTimelines({ getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_OFFSET', getters.loadedEventCount)
      Vue.http.get('/schedule_events',
        getters.filters
      ).then(response => {
        commit("SET_EVENT_COUNT", response.data.eventCount);
        commit("ADD_TIMELINES", response.data.timelines);
        resolve();
      }).catch(error => {
        console.log(error);
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
