import Vue from "vue";

// new Vue instance to use as event bus
var vueInstance = new Vue();

// Create plugin
let GlobalEventPlugin = {}

GlobalEventPlugin.install = function (Vue, options) {
  Vue.globalEvent = vueInstance // Access globally (from class)
  Vue.prototype.$globalEvent = vueInstance // acccess from vue instance
}

export default GlobalEventPlugin
