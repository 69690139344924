<template>
  <v-dialog
    dark
    v-model="dialog"
    width="500"
  >
    <v-card v-if="scheduleEvent">
      <v-card-title
        class="headline"
        primary-title
      >{{scheduleEvent.name}}</v-card-title>

      <!-- Event ended -->
      <v-card-text style="text-align: center;" class="subheading">
          <!-- Event ended -->
        <div v-if="isEnded">
          <span>This event has ended.</span>
        </div>
        <!-- no Source -->
        <div v-else-if="isLive && noSource">
          <span>If stream is not present in events or channels section please wait for event stream to be added.</span>
        </div>
        <!-- Event has not started -->
        <div v-else>
          <span>This event will start </span><b style="color: #e4b600;">{{startsIn}}</b>.<br />
          <span>Please check back at event start time.</span>
        </div>
        <br>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="danger"
          flat
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
        scheduleEvent: null
      }
    },
    created () {
      this.$globalEvent.$on("schedule-modal-open", (s_event) => {
        this.dialog        = true
        this.scheduleEvent = s_event
      });
    },
    computed: {
      startsIn() {
        return this.scheduleEvent.start_at.fromNow();
      },
      isEnded() {
        let now = this.$moment();
        return now.isAfter(this.scheduleEvent.end_at);
      },
      isLive() {
        let now = this.$moment();
        return now.isBetween(this.scheduleEvent.start_at, this.scheduleEvent.end_at);
      },
      noSource() {
        return this.scheduleEvent.streams.length == 0
      }
    }
  }
</script>

<style lang="scss" scoped>
.theme--dark.v-sheet {
  background-color: #242424f0;
}
</style>
